import React from "react";
import ReactDOM from "react-dom/client";

import WidgetWrapper from "./components/widget-wrapper";
import { Widget } from "./components/widget";
import { WidgetProvider } from "./lib/widget.context";
import { BookingEngineConfig } from "./types/general.types";

declare global {
  interface Window {
    initBookingEngine: (initialConfig?: BookingEngineConfig) => void;
  }
}

window.initBookingEngine = function (initialConfig) {
  const widgetDivs = document.querySelectorAll(".klarq-booking__widget");

  widgetDivs.forEach((widgetDiv) => {
    const root = ReactDOM.createRoot(widgetDiv);

    root.render(
      <React.StrictMode>
        <WidgetProvider initialConfig={initialConfig}>
          <WidgetWrapper>
            <Widget />
          </WidgetWrapper>
        </WidgetProvider>
      </React.StrictMode>
    );
  });
};
